import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Animated } from "react-animated-css";

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
    MdCheck, MdPhone, MdLocalPhone, MdMyLocation, MdKeyboardArrowDown
} from 'react-icons/md';

import {
    RiSmartphoneLine, RiHeartLine, RiArrowRightLine
} from 'react-icons/ri';

/**
 * Descomentar para BUILD
 */
import OwlCarousel from './../js/OwlCarousel';

/**
 * Descomentar para Desarrollo
 */
// import OwlCarousel from 'react-owl-carousel';



const IndexPage = () => {
    const data = useStaticQuery(graphql`
          query {
            slide1: file(relativePath: { eq: "slider1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            slide_2: file(relativePath: { eq: "slide_2.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }

            slide_3: file(relativePath: { eq: "slider_7.png" }) {
              childImageSharp {
                  fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            slide_4: file(relativePath: { eq: "slides_4.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                }
              }
            slide_5: file(relativePath: { eq: "slide_5.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            slide_6: file(relativePath: { eq: "slide_6.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            valores: file(relativePath: { eq: "valores.png" }) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            mision: file(relativePath: { eq: "mision.png" }) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            vision: file(relativePath: { eq: "vision.png" }) {
              childImageSharp {
                  fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid
                  }
              }
            }
            historia: file(relativePath: { eq: "historia.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
              }
            ruffles: file(relativePath: { eq: "ruffles.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cheetos: file(relativePath: { eq: "cheetos.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            snacks: file(relativePath: { eq: "snacks.png" }) {
                childImageSharp {
                    fluid(maxWidth: 188) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            marca_mary: file(relativePath: { eq: "marca_mary.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            marca_puig: file(relativePath: { eq: "marca_puig.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            marca_pepsico: file(relativePath: { eq: "pepsico_l.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            marca_goa: file(relativePath: { eq: "marca_goa.jpeg" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            marca_mondelez: file(relativePath: { eq: "marca_mondelez_logo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            marca_genica: file(relativePath: { eq: "genica_l.png" }) {
                childImageSharp {
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            marca_alimentos_polar: file(relativePath: { eq: "marcas_alimentos_polar.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            marca_stmoritz: file(relativePath: { eq: "marca_stmoritz.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            alimentos: file(relativePath: { eq: "alimentos.png" }) {
                childImageSharp {
                    fluid(maxWidth: 188) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            chocolateria: file(relativePath: { eq: "chocolateria.png" }) {
                childImageSharp {
                    fluid(maxWidth: 220) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            galleteria: file(relativePath: { eq: "galleteria.png" }) {
                childImageSharp {
                    fluid(maxWidth: 188) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            golosinas: file(relativePath: { eq: "golosinas.png" }) {
                childImageSharp {
                    fluid(maxWidth: 188) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            lays: file(relativePath: { eq: "lays.png" }) {
                childImageSharp {
                    fluid(maxWidth: 188) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            limpiezaycuidado: file(relativePath: { eq: "limpiezaycuidado.png" }) {
                childImageSharp {
                    fluid(maxWidth: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            battery: file(relativePath: { eq: "battery.png" }) {
                childImageSharp {
                    fluid(maxWidth: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            chesse_tris_up: file(relativePath: { eq: "chesse_tris_up.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            doritos_up: file(relativePath: { eq: "doritos_up.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pepitos_up: file(relativePath: { eq: "pepitos_up.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cheetos_t: file(relativePath: { eq: "cheeto_t.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            ruffles_t: file(relativePath: { eq: "ruffles_t.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            marca_cosmeticos_lara: file(relativePath: { eq: "marca_cosmeticos_lara.png" }) {
                childImageSharp {
                    fluid(maxWidth: 220) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            leche_extra_calcio: file(relativePath: { eq: "test_leche.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cheseetris_pl: file(relativePath: { eq: "cheseetris_pl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            doritos_pl: file(relativePath: { eq: "doritos_pl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pepitos_pl: file(relativePath: { eq: "pepitos_pl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            galletacludbsocial_tl: file(relativePath: { eq: "galletacludbsocial_tl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            galletamaria_tl: file(relativePath: { eq: "galletamaria_tl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            galletaoreo_tl: file(relativePath: { eq: "galletaoreo_tl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            drilo_estuche_tl: file(relativePath: { eq: "drilo_estuche_tl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            frutis_tl: file(relativePath: { eq: "frutis_tl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mentis_tl: file(relativePath: { eq: "mentis_tl.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icon_alcance: file(relativePath: { eq: "icon_alcance.png" }) {
                childImageSharp {
                    fluid(maxWidth: 510) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icon_store: file(relativePath: { eq: "icon_store.png" }) {
                childImageSharp {
                    fluid(maxWidth: 510) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icon_unetenos: file(relativePath: { eq: "icon_unetenos.png" }) {
                childImageSharp {
                    fluid(maxWidth: 510) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            paso_1: file(relativePath: { eq: "sale.png" }) {
                childImageSharp {
                    fluid(maxWidth: 510) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            paso_2: file(relativePath: { eq: "icon_estrechar_manos.png" }) {
                childImageSharp {
                    fluid(maxWidth: 510) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

            paso_3: file(relativePath: { eq: "client.png" }) {
                childImageSharp {
                    fluid(maxWidth: 510) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            paso_4: file(relativePath: { eq: "truck.png" }) {
                childImageSharp {
                    fluid(maxWidth: 510) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            gomby: file(relativePath: { eq: "gomby.png" }) {
                childImageSharp {
                    fluid(maxWidth: 510) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icon_register: file(relativePath: { eq: "icon_register.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            stock: file(relativePath: { eq: "stock.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icon_contact_center: file(relativePath: { eq: "icon_contact_center.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icon_paper: file(relativePath: { eq: "icon_paper.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icon_man: file(relativePath: { eq: "icon_man.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            icon_checklist: file(relativePath: { eq: "icon_checklist.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cheetosP: file(relativePath: { eq: "Cheetos.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mani_jacks: file(relativePath: { eq: "jacks_catego.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            natuchips: file(relativePath: { eq: "Natuchips.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            soda_puig: file(relativePath: { eq: "Soda_Puig.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            marilu_vainilla: file(relativePath: { eq: "Marilu_Vainilla.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            gallt: file(relativePath: { eq: "Gallt.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            arroces_mary: file(relativePath: { eq: "Arroces_Mary.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            harina_pan: file(relativePath: { eq: "Harina_pan.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mayonesa_kraft: file(relativePath: { eq: "Mayonesa_kraft.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pastas_galo: file(relativePath: { eq: "pastas_galo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            crema_arroz: file(relativePath: { eq: "Crema_arroz.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            chocolate_stadium: file(relativePath: { eq: "Chocolate_stadium.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            flaquito_cl: file(relativePath: { eq: "Flaquito.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            choco_latte_crujiente: file(relativePath: { eq: "choco_latte_crujiente.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            astrolates: file(relativePath: { eq: "Astrolates.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            blanc: file(relativePath: { eq: "Blanc.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            barki_lates: file(relativePath: { eq: "Barki_lates.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            gusi: file(relativePath: { eq: "Gusi.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            coquis_ristra: file(relativePath: { eq: "Coquis_ristra.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            otto: file(relativePath: { eq: "Otto.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            las_llaves_pastilla: file(relativePath: { eq: "las_llaves_pastilla.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            bano_crema_stefany: file(relativePath: { eq: "bano_crema_stefany.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            gel_masaje_stefany: file(relativePath: { eq: "gel_masaje_stefany.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            keratina_stefany: file(relativePath: { eq: "keratina_stefany.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            shampoo_baby_flash: file(relativePath: { eq: "shampoo_baby_flash.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            talco_acibor: file(relativePath: { eq: "talco_acibor.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            club_social_dt: file(relativePath: { eq: "club_social_dt.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            arroz_dt: file(relativePath: { eq: "arroz_dt.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            leche_dt: file(relativePath: { eq: "leche_dt.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            chocollate_dt: file(relativePath: { eq: "Choco_latte_Crujiente.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            soda_dt: file(relativePath: { eq: "soda_dt.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pilas_aaa_gp_ultra: file(relativePath: { eq: "pilas_aaa_gp_ultra.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            bombilla: file(relativePath: { eq: "bombilla.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pilas_3v: file(relativePath: { eq: "pilas_3v.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pilas_aa_gp_dreencell: file(relativePath: { eq: "pilas_aa_gp_dreencell.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pilas_d_greencell: file(relativePath: { eq: "pilas_d_greencell.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            pilas_gp_reciko: file(relativePath: { eq: "pilas_gp_reciko.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }

          }
      `
    )


    const [heightSlider, setHeightSlider] = useState(667);
    const [c_stateBtn, setC_stateBtn] = useState(false);
    const [c_titleBtn, setC_titleBtn] = useState("Enviar");
    const [Test, setTest] = useState(":(");

    useEffect(() => {
        setTimeout(() => {

            let miHeight = window.screen.availHeight
            if (window.innerWidth <= 1130) {
                setHeightSlider(miHeight);
            } else {
                setHeightSlider(miHeight - 100);
            }

        }, 690);
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setTest(":)");
        }, 690);
    }, []);

    const send_form = (e) => {
        if( typeof window !== `undefined`){
            e.preventDefault();
            setC_stateBtn(true);
            setC_titleBtn("Enviando...");

            setTimeout(() => {
                document.querySelector('#form_contact').submit();
            }, 10);
        }
    }

    const send_form_joinus = (e) => {
        if( typeof window !== `undefined`){
            e.preventDefault();
            setC_stateBtn(true);
            setC_titleBtn("Enviando...");

            setTimeout(() => {
                document.querySelector('#form_joinus').submit();
            }, 10);
        }
    }


    return (

        <Layout changeHeader={1}>
            <SEO title="Inicio" />

            {/*Slider*/}
            <section id="home-section" className="hero text_slide" style={{ maxHeight: heightSlider + 'px' }}>
                <div className="home-slider js-fullheight owl-carousel">
                    <OwlCarousel
                        className="owl-theme"
                        loop={false}
                        margin={0}
                        nav={true}
                        rewind={true}
                        // animateIn={'fadeInRight'}
                        // animateOut= {false}
                        items={1}
                        autoplay={true}
                        mouseDrag={false}
                        autoplayHoverPause={true}
                        autoplayTimeout={8000}
                        navText={[`<b class="customNav"><</b>`, `<b class="customNav">></b>`]}
                    >


                        <div className="slider-item js-fullheight" style={{ height: heightSlider + 'px' }}>
                            <div className="overlay"></div>
                            <div className="container-fluid p-0">
                                <div className="row d-md-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" style={{ height: heightSlider + 'px', maxHeight: '720px' }}>
                                    <div className="one-third order-md-last img js-fullheight" style={{ backgroundImage: `url(${data.slide1.childImageSharp.fluid.src})`, height: '100%' }}>

                                        <div className="overlay"></div>
                                    </div>
                                    <div className="one-forth d-flex js-fullheight align-items-center" >
                                        <div className="text" >
                                            <Animated animationIn="fadeInLeft" animationInDuration="1100" animationOut="fadeOut" isVisible={true}>
                                                <span className="subheading">Bienvenido <span className='text-lowercase'>a</span> Distribuidora Yonlui, <span className='text-lowercase'> c.a.</span></span> <br />

                                                <h1 className="mb-4 mt-3"><span> Nosotros</span></h1>
                                                <p className="text-left">Somos una Empresa de Distribución y Logística de productos  Alimenticios en el Estado Portuguesa.</p>

                                            </Animated>
                                            <Animated animationIn="fadeInUp" animationInDuration="1100" animationOut="fadeOut" isVisible={true}>

                                                <p><Link to={'/nosotros'} className="btn btn_white px-5 py-3 mt-3">Ver más</Link></p>
                                            </Animated>


                                        </div>
                                    </div>
                                    <div className="con_text_slogan">
                                        <span className="text-ambar text_slogan text-right mr-2"> Un Cargamento de Sabor y algo más...</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="slider-item js-fullheight" style={{ height: heightSlider + 'px' }}>
                            <div className="overlay"></div>
                            <div className="container-fluid p-0">
                                <div className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" style={{ height: heightSlider + 'px', maxHeight: '720px' }}>
                                    <div className="one-third order-md-last img js-fullheight" style={{ backgroundImage: `url(${data.slide_2.childImageSharp.fluid.src})`, height: '100%' }}>
                                        <div className="overlay"></div>
                                    </div>
                                    <div className="one-forth d-flex js-fullheight align-items-center" >
                                        <div className="text" >
                                            <div data-sal="slide-down" data-sal-duration="2000" data-sal-easing="ease">
                                                <span className="subheading">Bienvenido <span className='text-lowercase'>a</span> Distribuidora Yonlui, <span className='text-lowercase'> c.a.</span></span><br />
                                                <h1 className="mb-4 mt-3"><span>Nuestas Marcas</span> </h1>
                                                <p className="text-left">Contamos con una variada selección de marcas líderes de alimentos, confites, snacks y cuidado personal de alta rotación y excelente rentabilidad.</p>
                                            </div>

                                            <p data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                                                <Link to="/marcas" className="btn btn_white px-5 py-3 mt-3">Ver más</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="con_text_slogan">
                                        <span className="text-ambar text_slogan text-right mr-2"> Un Cargamento de Sabor y algo más...</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item js-fullheight" style={{ height: heightSlider + 'px' }}>
                            <div className="overlay"></div>
                            <div className="container-fluid p-0">
                                <div className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" style={{ height: heightSlider + 'px', maxHeight: '720px' }}>
                                    <div className="one-third order-md-last img js-fullheight" style={{ backgroundImage: `url(${data.slide_3.childImageSharp.fluid.src})`, height: '100%' }}>
                                        <div className="overlay"></div>
                                    </div>
                                    <div className="one-forth d-flex js-fullheight align-items-center" >
                                        <div className="text" >
                                            <div data-sal="slide-down" data-sal-duration="1000" data-sal-easing="ease">
                                                <span className="subheading">Bienvenido <span className='text-lowercase'>a</span> Distribuidora Yonlui, <span className='text-lowercase'> c.a.</span></span><br />
                                                <h1 className="mb-4 mt-3"><span>Por qué Seleccionarnos</span></h1>
                                            </div>

                                            <p className="text-left">
                                                <div data-sal="slide-right" data-sal-duration="1300" data-sal-easing="ease">
                                                    <strong>-</strong> Somos Distribuidores directos de los Fabricantes.<br />
                                                </div>
                                                <div data-sal="slide-right" data-sal-duration="1600" data-sal-easing="ease">
                                                    <strong>-</strong> Almacenamos los productos correctamente.<br />

                                                </div>
                                                <div data-sal="slide-right" data-sal-duration="1900" data-sal-easing="ease">
                                                    <strong>-</strong> Despachamos máximo a 48 horas. <br />

                                                </div>
                                                <div data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                                                    <strong>-</strong> No somos revendedores.
                                                </div>
                                            </p>

                                            <p data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                                <a href={'javascript:void(0);'} onClick={() => scrollTo('#aliados')} className="btn btn_white px-5 py-3 mt-3">Ver más</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="con_text_slogan">
                                        <span className="text-ambar text_slogan text-right mr-2"> Un Cargamento de Sabor y algo más...</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item js-fullheight" style={{ height: heightSlider + 'px' }}>
                            <div className="overlay"></div>
                            <div className="container-fluid p-0">
                                <div className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" style={{ height: heightSlider + 'px', maxHeight: '720px' }}>
                                    <div className="one-third order-md-last img js-fullheight" style={{ backgroundImage: `url(${data.slide_4.childImageSharp.fluid.src})`, height: '100%', backgroundPosition: 'left center' }}>
                                        <div className="overlay"></div>
                                    </div>
                                    <div className="one-forth d-flex js-fullheight align-items-center" >
                                        <div className="text">
                                            <div data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease">
                                                <span className="subheading">Bienvenido <span className='text-lowercase'>a</span> Distribuidora Yonlui, <span className='text-lowercase'> c.a.</span></span><br />
                                                <h1 className="mb-4 mt-3"><span>Nuestros Clientes</span></h1>
                                            </div>
                                            <p className="text-left" data-sal="slide-left" data-sal-duration="1000" data-sal-easing="ease">
                                                Cualquier comercio establecido en nuestra zona o en proceso de formación.
                                            </p>

                                            <p data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease"><a href={'javascript:void(0);'} onClick={() => scrollTo('#registrate')} className="btn btn_white px-5 py-3 mt-3">Ver más</a></p>
                                        </div>
                                    </div>
                                    <div className="con_text_slogan">
                                        <span className="text-ambar text_slogan text-right mr-2"> Un Cargamento de Sabor y algo más...</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item js-fullheight" style={{ height: heightSlider + 'px' }}>
                            <div className="overlay"></div>
                            <div className="container-fluid p-0">
                                <div className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" style={{ height: heightSlider + 'px', maxHeight: '720px' }}>
                                    <div className="one-third order-md-last img js-fullheight" style={{ backgroundImage: `url(${data.slide_5.childImageSharp.fluid.src})`, height: '100%' }}>
                                        <div className="overlay"></div>
                                    </div>
                                    <div className="one-forth d-flex js-fullheight align-items-center" >
                                        <div className="text" >
                                            <div data-sal="slide-down" data-sal-duration="1400" data-sal-easing="ease">

                                                <span className="subheading">Bienvenido <span className='text-lowercase'>a</span> Distribuidora Yonlui, <span className='text-lowercase'> c.a.</span></span><br />
                                                <h1 className="mb-4 mt-3"><span>Incorpórate a nuestra Red de Distribución</span></h1>
                                                <p className="text-left">
                                                    Llena el formulario y regístrate. Te contactaremos lo antes posible.
                                                </p>
                                            </div>

                                            <p data-sal="slide-right" data-sal-duration="1800" data-sal-easing="ease">
                                                <Link to="/registrate" className="btn btn_white px-5 py-3 mt-3">Ver más</Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="con_text_slogan">
                                        <span className="text-ambar text_slogan text-right mr-2"> Un Cargamento de Sabor y algo más...</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item js-fullheight" style={{ height: heightSlider + 'px' }}>
                            <div className="overlay"></div>
                            <div className="container-fluid p-0">
                                <div className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" style={{ height: heightSlider + 'px', maxHeight: '720px' }} >
                                    <div className="one-third order-md-last img js-fullheight" style={{ backgroundImage: `url(${data.slide_6.childImageSharp.fluid.src})`, height: '100%' }}>
                                        <div className="overlay"></div>
                                    </div>

                                    <div className="one-forth d-flex js-fullheight align-items-center">
                                        <div className="text" >
                                            <div data-sal="slide-down" data-sal-duration="2000" data-sal-easing="ease">

                                                <span className="subheading">Bienvenido <span className='text-lowercase'>a</span> Distribuidora Yonlui, <span className='text-lowercase'> c.a.</span></span><br />
                                                <h1 className="mb-4 mt-3"><span>Si necesita almacenar sus Productos</span></h1>
                                                <p className="text-left">
                                                    Ofrecemos a productores, importadores y comerciantes la posibilidad de almacenar sus inventarios a bajo costo.
                                                </p>
                                            </div>

                                            <p>
                                                <a href={'javascript:void(0);'} onClick={() => scrollTo('#servicios')} className="btn btn_white px-5 py-3 mt-3">Ver más</a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="con_text_slogan">
                                        <span className="text-ambar text_slogan text-right mr-2"> Un Cargamento de Sabor y algo más...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>

            </section>

            {/*Nosotros*/}
            <section className="ftco-section ftco-services-2" id="nosotros">
                <div className="container">
                    <div className="row justify-content-center pb-5">
                        <div className="col-md-12 heading-section text-center " >
                            <span className="subheading">¿Quiénes somos?</span>
                            <h2 className="mb-4">Nosotros</h2>
                            <p>Somos una empresa dedicada a la Distribución y almacenaje de diferentes tipos de productos</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md d-flex align-self-stretch ">
                            <div className="media block-6 services text-center d-block">
                                <div className="icon" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                    <img src={data.historia.childImageSharp.fluid.src} alt="img valores" width="30%" />
                                </div>
                                <div className="media-body" data-sal="fade-in" data-sal-duration="1300" data-sal-easing="ease">
                                    <h3 className="heading mb-3">Historia</h3>
                                    <p>Distribuidora Yonlui, c.a. fue fundada en Noviembre del 2008 e inicia actividades formales en Abril del 2009. Su cobertura es el Estado Portuguesa...</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md d-flex align-self-stretch ">
                            <div className="media block-6 services text-center d-block">
                                <div className="icon" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                                    <img src={data.mision.childImageSharp.fluid.src} alt="img misión" width="30%" />
                                </div>
                                <div className="media-body" data-sal="fade-in" data-sal-duration="1300" data-sal-easing="ease">
                                    <h3 className="heading mb-3">Misión</h3>
                                    <p>Garantizar el abastecimiento de productos alimenticios al comercio minorista, integrando al suministro la calidad, cantidad, precio y tiempo...</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md d-flex align-self-stretch ">
                            <div className="media block-6 services text-center d-block">
                                <div className="icon" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                    <img src={data.vision.childImageSharp.fluid.src} alt="img visión" width="30%" />
                                </div>
                                <div className="media-body" data-sal="fade-in" data-sal-duration="1300" data-sal-easing="ease">
                                    <h3 className="heading mb-3">Visión</h3>
                                    <p>Convertirnos en el principal operador logístico y de Distribución para la zona de los Llanos Occidentales.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md d-flex align-self-stretch ">
                            <div className="media block-6 services text-center d-block">
                                <div className="icon" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                    <img src={data.valores.childImageSharp.fluid.src} alt="img valores" width="30%" />
                                </div>
                                <div className="media-body" data-sal="fade-in" data-sal-duration="1300" data-sal-easing="ease">
                                    <h3 className="heading mb-3">Valores</h3>
                                    <p>Somos responsables de todas las decisiones que tomamos y de las consecuencias que tengan las citadas decisiones ante quien corresponda.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 text-center" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                            <Link to="/nosotros" className="btn btn-primary px-4 py-2 mt-3">Ver más</Link>
                        </div>

                    </div>
                </div>
            </section>

            {/*Productos Destacados*/}
            <section className="ftco-section bg-light-1" id="products">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-5">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="800" data-sal-easing="ease">
                            <span className="subheading">Nuestros productos destacados</span>
                            <h2 className="mb-4">Productos Destacados</h2>
                            <p>Nuestros productos más destacados y de mejor calidad</p>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-md-6 col-sm-12 col-lg-4 mb-3">
                            <div className="blog-entry justify-content-end">
                                <a href="https://tienda.yonlui.com" className="block-20" style={{ backgroundImage: `url(${data.chesse_tris_up.childImageSharp.fluid.src})` }} data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease">
                                </a>
                                <div className="text float-right d-block box_sh_2">
                                    <div className="d-flex align-items-center pt-2 mb-4 topp">
                                        {/* <div className="one mr-2">
                                            <span className="day">04</span>
                                        </div>
                                        <div className="two">
                                            <span className="yr">2019</span>
                                            <span className="mos">abril</span>
                                        </div> */}
                                    </div>
                                    <h3 className="heading"><a href="https://tienda.yonlui.com">Cheese Tris</a></h3>
                                    <div className="d-flex align-items-center mt-4 meta">
                                        <p className="mb-0"><a href="https://tienda.yonlui.com" className="btn btn-primary">Ver más <span className="ion-ios-arrow-round-forward"></span></a></p>
                                        <p className="ml-auto mb-0">
                                            <a href="https://tienda.yonlui.com" className="mr-2"><RiHeartLine color="#ef4431" size="1.5em" /></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-lg-4 mb-3">
                            <div className="blog-entry justify-content-end">
                                <a href="https://tienda.yonlui.com" className="block-20" style={{ backgroundImage: `url(${data.club_social_dt.childImageSharp.fluid.src})` }} data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                                </a>
                                <div className="text float-right d-block box_sh_2">
                                    <div className="d-flex align-items-center pt-2 mb-4 topp">
                                        {/* <div className="one mr-2">
                                            <span className="day">04</span>
                                        </div>
                                        <div className="two">
                                            <span className="yr">2019</span>
                                            <span className="mos">abril</span>
                                        </div> */}
                                    </div>
                                    <h3 className="heading"><a href="https://tienda.yonlui.com">Club Social</a></h3>
                                    {/* <p>Producto líder en Venezuela y el Mundo.</p> */}
                                    <div className="d-flex align-items-center mt-4 meta">
                                        <p className="mb-0"><a href="https://tienda.yonlui.com" className="btn btn-primary">Ver más <span className="ion-ios-arrow-round-forward"></span></a></p>
                                        <p className="ml-auto mb-0">
                                            <a href="https://tienda.yonlui.com" className="mr-2"><RiHeartLine color="#ef4431" size="1.5em" /></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-lg-4 mb-3">
                            <div className="blog-entry">
                                <a href="https://tienda.yonlui.com" className="block-20" style={{ backgroundImage: `url(${data.arroz_dt.childImageSharp.fluid.src})` }} data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                                </a>
                                <div className="text float-right d-block box_sh_2">
                                    <div className="d-flex align-items-center pt-2 mb-4 topp">
                                        {/* <div className="one mr-2">
                                            <span className="day">04</span>
                                        </div>
                                        <div className="two">
                                            <span className="yr">2019</span>
                                            <span className="mos">abril</span>
                                        </div> */}
                                    </div>
                                    <h3 className="heading" >
                                        <a href="https://tienda.yonlui.com">Arroz Superior / Tradicional  </a> <br />
                                    </h3>
                                    <div className="d-flex align-items-center mt-4 meta">
                                        <p className="mb-0"><a href="https://tienda.yonlui.com" className="btn btn-primary">Ver más <span className="ion-ios-arrow-round-forward"></span></a></p>
                                        <p className="ml-auto mb-0">
                                            <a href="https://tienda.yonlui.com" className="mr-2"> <RiHeartLine color="#ef4431" size="1.5em" /></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-lg-4 mb-3">
                            <div className="blog-entry">
                                <a href="https://tienda.yonlui.com" className="block-20" style={{ backgroundImage: `url(${data.leche_dt.childImageSharp.fluid.src})` }} data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                                </a>
                                <div className="text float-right d-block box_sh_2">
                                    <div className="d-flex align-items-center pt-2 mb-4 topp">
                                        {/* <div className="one mr-2">
                                            <span className="day">04</span>
                                        </div>
                                        <div className="two">
                                            <span className="yr">2019</span>
                                            <span className="mos">abril</span>
                                        </div> */}
                                    </div>
                                    <h3 className="heading"><a href="https://tienda.yonlui.com">Leche Campestre</a></h3>
                                    <div className="d-flex align-items-center mt-4 meta">
                                        <p className="mb-0"><a href="https://tienda.yonlui.com" className="btn btn-primary">Ver más <span className="ion-ios-arrow-round-forward"></span></a></p>
                                        <p className="ml-auto mb-0">
                                            <a href="https://tienda.yonlui.com" className="mr-2"> <RiHeartLine color="#ef4431" size="1.5em" /></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-lg-4 mb-3">
                            <div className="blog-entry">
                                <a href="https://tienda.yonlui.com" className="block-20" style={{ backgroundImage: `url(${data.chocollate_dt.childImageSharp.fluid.src})` }} data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                                </a>
                                <div className="text float-right d-block box_sh_2">
                                    <div className="d-flex align-items-center pt-2 mb-4 topp">
                                        {/* <div className="one mr-2">
                                            <span className="day">04</span>
                                        </div>
                                        <div className="two">
                                            <span className="yr">2019</span>
                                            <span className="mos">abril</span>
                                        </div> */}
                                    </div>
                                    <h3 className="heading"><a href="https://tienda.yonlui.com">Stadium Display</a></h3>
                                    <div className="d-flex align-items-center mt-4 meta">
                                        <p className="mb-0"><a href="https://tienda.yonlui.com" className="btn btn-primary">Ver más <span className="ion-ios-arrow-round-forward"></span></a></p>
                                        <p className="ml-auto mb-0">
                                            <a href="https://tienda.yonlui.com" className="mr-2"> <RiHeartLine color="#ef4431" size="1.5em" /></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 col-lg-4 mb-3">
                            <div className="blog-entry">
                                <a href="https://tienda.yonlui.com" className="block-20" style={{ backgroundImage: `url(${data.soda_dt.childImageSharp.fluid.src})` }} data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                                </a>
                                <div className="text float-right d-block box_sh_2">
                                    <div className="d-flex align-items-center pt-2 mb-4 topp">
                                        {/* <div className="one mr-2">
                                            <span className="day">04</span>
                                        </div>
                                        <div className="two">
                                            <span className="yr">2019</span>
                                            <span className="mos">abril</span>
                                        </div> */}
                                    </div>
                                    <h3 className="heading"><a href="https://tienda.yonlui.com">Soda Puig Enriquecida</a></h3>
                                    <div className="d-flex align-items-center mt-4 meta">
                                        <p className="mb-0"><a href="https://tienda.yonlui.com" className="btn btn-primary">Ver más <span className="ion-ios-arrow-round-forward"></span></a></p>
                                        <p className="ml-auto mb-0">
                                            <a href="https://tienda.yonlui.com" className="mr-2"> <RiHeartLine color="#ef4431" size="1.5em" /></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Categorias*/}
            <section className="ftco-section ftco-schedule" id="categorias">
                <div className="container-fluid">
                    <div className="row justify-content-center pb-5">
                        <div className="col-md-12 heading-section text-center " data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                            <span className="subheading">Categorías</span>
                            <h2 className="mb-4">Categorías</h2>
                            <p>Contamos con una gran variedad de categorías en aperitivos, bebidas y más.</p>
                        </div>
                    </div>
                    <div className="ftco-schedule">
                        <div className="row">
                            <div className="col-md-4 nav-link-wrap">
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link active" style={{ padding: '0px !important' }} id="v-pills-10-tab" data-toggle="pill" href="#v-pills-10" role="tab" aria-controls="v-pills-10" aria-selected="true" data-sal="slide-right" data-sal-duration="500" data-sal-easing="ease">
                                        <div className="d-flex" >
                                            <div className="img-category">
                                                <img src={data.snacks.childImageSharp.fluid.src} alt="logo categorías" width="70px" />
                                            </div>
                                            <p className="text my-auto ml-4">Snacks</p>

                                        </div>
                                    </a>
                                    <a className="nav-link " style={{ padding: '0px !important' }} id="v-pills-11-tab" data-toggle="pill" href="#v-pills-11" role="tab" aria-controls="v-pills-11" aria-selected="true" data-sal="slide-right" data-sal-duration="700" data-sal-easing="ease">
                                        <div className="d-flex" >
                                            <div className="img-category">
                                                <img src={data.galleteria.childImageSharp.fluid.src} alt="logo categorías" width="70px" />
                                            </div>
                                            <p className="text my-auto ml-4">Galletería</p>

                                        </div>
                                    </a>
                                    <a className="nav-link " style={{ padding: '0px !important' }} id="v-pills-9-tab" data-toggle="pill" href="#v-pills-9" role="tab" aria-controls="v-pills-9" aria-selected="true" data-sal="slide-right" data-sal-duration="900" data-sal-easing="ease">
                                        <div className="d-flex" >
                                            <div className="img-category">
                                                <img src={data.alimentos.childImageSharp.fluid.src} alt="logo categorías" width="70px" />
                                            </div>
                                            <p className="text my-auto ml-4">Alimentos</p>

                                        </div>
                                    </a>

                                    <a className="nav-link" style={{ padding: '0px !important' }} id="v-pills-8-tab" data-toggle="pill" href="#v-pills-8" role="tab" aria-controls="v-pills-8" aria-selected="true" data-sal="slide-right" data-sal-duration="1200" data-sal-easing="ease">
                                        <div className="d-flex" >
                                            <div className="img-category">
                                                <img src={data.chocolateria.childImageSharp.fluid.src} alt="logo categorías" width="70px" />
                                            </div>
                                            <p className="my-auto ml-4">Chocolatería</p>

                                        </div>
                                    </a>
                                    <a className="nav-link " style={{ padding: '0px !important' }} id="v-pills-12-tab" data-toggle="pill" href="#v-pills-12" role="tab" aria-controls="v-pills-12" aria-selected="true" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                                        <div className="d-flex">
                                            <div className="img-category">
                                                <img src={data.golosinas.childImageSharp.fluid.src} alt="logo categorías" width="70px" />
                                            </div>
                                            <p className="text my-auto ml-4">Golosinas</p>

                                        </div>
                                    </a>
                                    <a className="nav-link " style={{ padding: '0px !important' }} id="v-pills-13-tab" data-toggle="pill" href="#v-pills-13" role="tab" aria-controls="v-pills-13" aria-selected="true" data-sal="slide-right" data-sal-duration="1900" data-sal-easing="ease">
                                        <div className="d-flex">
                                            <div className="img-category">
                                                <img src={data.limpiezaycuidado.childImageSharp.fluid.src} alt="logo categorías" width="70px" />
                                            </div>
                                            <p className="text my-auto ml-4">Limpieza y cuidado personal</p>

                                        </div>
                                    </a>
                                    <a className="nav-link " style={{ padding: '0px !important' }} id="v-pills-14-tab" data-toggle="pill" href="#v-pills-14" role="tab" aria-controls="v-pills-14" aria-selected="true" data-sal="slide-right" data-sal-duration="1900" data-sal-easing="ease">
                                        <div className="d-flex">
                                            <div className="img-category">
                                                <img src={data.battery.childImageSharp.fluid.src} alt="logo categorías" width="70px" />
                                            </div>
                                            <p className="text my-auto ml-4">Misceláneas</p>

                                        </div>
                                    </a>


                                </div>
                            </div>
                            <div className="col-md-8 tab-wrap">
                                <div className="tab-content" id="v-pills-tabContent">
                                    {/* SNACKS */}
                                    <div className="tab-pane fade show active" id="v-pills-10" role="tabpanel" aria-labelledby="v-pills-day-10-tab">
                                        <div className="coach-wrap  d-sm-flex">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-2 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.cheseetris_pl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease" >
                                                        {/* <center>
                                                            <img src={data.cheseetris_pl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Chesee Tris 150GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-2 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.doritos_pl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease" >
                                                        {/* <center>
                                                            <img src={data.doritos_pl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Doritos Mega Queso 150GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-2 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.pepitos_pl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease" >
                                                        {/* <center>
                                                            <img src={data.pepitos_pl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Pepito 6 X 80GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-2 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.natuchips.childImageSharp.fluid.src})`, width: '100%', height: '270px' }} data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease" >
                                                        {/* <center>
                                                            <img src={data.natuchips.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Natuchips Platanitos Nat 150GR</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-2 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.mani_jacks.childImageSharp.fluid.src})`, width: '100%', height: '270px' }} data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease" >
                                                        {/* <center>
                                                            <img src={data.mani_jacks.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Mani Jacks Bolsa 175GR</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-2 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.cheetosP.childImageSharp.fluid.src})`, width: '100%', height: '270px' }} data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease" >
                                                        {/* <center>
                                                            <img src={data.cheetosP.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Cheetos Mega Puffs 6 X 110GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 text-center">
                                                    <a href="https://tienda.yonlui.com" className="btn btn-primary px-4 py-2 mt-3">Ver más</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* Galleteria */}
                                    <div className="tab-pane fade" id="v-pills-11" role="tabpanel" aria-labelledby="v-pills-day-11-tab">
                                        <div className="coach-wrap  d-sm-flex">
                                            <div className="row">

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.galletacludbsocial_tl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.galletacludbsocial_tl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Galleta Club Social 6S 156GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.galletamaria_tl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.galletamaria_tl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Galleta María Caledonia 250GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.galletaoreo_tl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.galletaoreo_tl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Oreo Chocolate OW 6s X 216GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.soda_puig.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.soda_puig.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Soda Puig Enriquecida 240GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.marilu_vainilla.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.marilu_vainilla.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Marilu Vainilla Tubo 216GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.gallt.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.gallt.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Gallt 2 Choc/Vain Tubo 216GR </h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 text-center">
                                                    <a href="https://tienda.yonlui.com" className="btn btn-primary px-4 py-2 mt-3">Ver más</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* Alimentos */}
                                    <div className="tab-pane fade show" id="v-pills-9" role="tabpanel" aria-labelledby="day-9-tab">
                                        <div className="coach-wrap ">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.arroces_mary.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.arroces_mary.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Arroz Mary 24 x 1kg / 30 × 800gr</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.harina_pan.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.harina_pan.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Pan Harina Maiz 10 X 1KG</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.mayonesa_kraft.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.mayonesa_kraft.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Mayonesa Kraft 6 X 445GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.leche_extra_calcio.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.leche_extra_calcio.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Leche Completa Extra Calcio 1KG</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.pastas_galo.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.pastas_galo.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Galo Pasta Spaguetti 15 X 500GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.crema_arroz.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.crema_arroz.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Crema Arroz Mary en Sobre y Pote</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 text-center">
                                                    <a href="https://tienda.yonlui.com" className="btn btn-primary px-4 py-2 mt-3">Ver más</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Chocolateria */}
                                    <div className="tab-pane fade show" id="v-pills-8" role="tabpanel" aria-labelledby="day-8-tab">
                                        <div className="coach-wrap ">
                                            <div className="row">

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.chocolate_stadium.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.chocolate_stadium.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Stadium 15 X 25GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.flaquito_cl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.flaquito_cl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Flaquito Duo, Diplay y Pote</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.choco_latte_crujiente.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.choco_latte_crujiente.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Choco Latte 12 X 32GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.astrolates.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.astrolates.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Astrolates 12 X 30GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.blanc.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.blanc.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Choco Blanc 12 X 30GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.barki_lates.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.barki_lates.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Barki Lates Estuche 180GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 text-center">
                                                    <a href="https://tienda.yonlui.com" className="btn btn-primary px-4 py-2 mt-3">Ver más</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Golosinas */}
                                    <div className="tab-pane fade" id="v-pills-12" role="tabpanel" aria-labelledby="v-pills-day-12-tab">
                                        <div className="coach-wrap  d-sm-flex">
                                            <div className="row">
                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.mentis_tl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.mentis_tl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Caramelos Mentis Bolsa 500GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.frutis_tl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.frutis_tl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Caramelos Frutis Bolsa 350GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.drilo_estuche_tl.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.drilo_estuche_tl.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Drilo Estuche 20 X 11GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.gusi.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.gusi.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Gusi al Rescate 12 X 30GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.coquis_ristra.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.coquis_ristra.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Coquis Ristra 12 X 30GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.otto.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.otto.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Otto Salvavidas 12 X 30GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 text-center">
                                                    <a href="https://tienda.yonlui.com" className="btn btn-primary px-4 py-2 mt-3">Ver más</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* Limpieza y cuiadado */}
                                    <div className="tab-pane fade" id="v-pills-13" role="tabpanel" aria-labelledby="v-pills-day-12-tab">
                                        <div className="coach-wrap  d-sm-flex">
                                            <div className="row">

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.las_llaves_pastilla.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.las_llaves_pastilla.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Las LLaves Multiuso Pastillas 130GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.bano_crema_stefany.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.bano_crema_stefany.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Baño de Crema Cera de Abejas Stefany 250GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.gel_masaje_stefany.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.gel_masaje_stefany.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Gel para Masajes Stefany 130GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.keratina_stefany.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.keratina_stefany.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Baño de Crema Keratina Stefany 250GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.shampoo_baby_flash.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.shampoo_baby_flash.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Shampoo Baby Flash 900ML</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.talco_acibor.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.talco_acibor.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Talco para Pies Acibor 60GR</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 text-center">
                                                    <a href="https://tienda.yonlui.com" className="btn btn-primary px-4 py-2 mt-3">Ver más</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    {/* Misceláneaso */}
                                    <div className="tab-pane fade" id="v-pills-14" role="tabpanel" aria-labelledby="v-pills-day-12-tab">
                                        <div className="coach-wrap  d-sm-flex">
                                            <div className="row">

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.pilas_aaa_gp_ultra.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.las_llaves_pastilla.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Ultra Alcalina Modelo GP15AU-2U4 Blister de 4</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.bombilla.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.bano_crema_stefany.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Bombillo led inteligente 9w 6500K AC - 85 A 265 voltios</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.pilas_3v.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.gel_masaje_stefany.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Bateria Recargable Modelo GPCR2025-7C2 Blister de 2</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.pilas_aa_gp_dreencell.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.keratina_stefany.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Alcalina Alto voltaje Modelo GP15G-2U4 Blister de 4</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.pilas_d_greencell.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.shampoo_baby_flash.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Greencell Modelo GP13G-2S2 blister de 2</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-sm-6 col-lg-4 my-auto">
                                                    <div className="card card-process mb-5 box_sh_2 img_reset" style={{ backgroundImage: `url(${data.pilas_gp_reciko.childImageSharp.fluid.src})`, width: '100%', height: '270px' }}>
                                                        {/* <center>
                                                            <img src={data.talco_acibor.childImageSharp.fluid.src} width="100%" className="resolu_img_cat" alt="Pepsi max" />
                                                        </center> */}
                                                        <div className="card-body" style={{ padding: '0px', marginTop: '-2px' }}></div>
                                                        <div className="img-text-flotante d-flex align-items-center justify-content-center">
                                                            <h6 className="mt-2">Bateria Recargable Modelo GPU211USB27OEFR-2GBLA2 Blister de 2</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-12 col-md-12 text-center">
                                                    <a href="https://tienda.yonlui.com" className="btn btn-primary px-4 py-2 mt-3">Ver más</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Proovedores*/}
            <section className="ftco-section ftco-services-2 d-none d-xs-none d-md-block" style={{ padding: '7em 0 1em 0' }} id="aliados">
                <div className="container-fluid">
                    <div className="row justify-content-center mb-2 pb-5">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                            <span className="subheading">Aliados</span>
                            <h3 className="mb-4">SOMOS DISTRIBUIDORES DIRECTOS DE LAS SIGUIENTES EMPRESAS</h3>
                            <p>Tenemos relaciones comerciales directas con las siguientes empresas</p>
                        </div>
                    </div>
                    <div className="row justify-content-center" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">

                        <div className="col-sm-3 col-md-1">
                            <img src={data.marca_mary.childImageSharp.fluid.src} className="img-fluid" alt="ad rush" style={{ maxWidth: '130px' }} />
                        </div>
                        <div className="col-sm-3 col-md-1 ml-4 mr-5">
                            <img src={data.marca_genica.childImageSharp.fluid.src} className="img-fluid" alt="natuchips" style={{ maxWidth: '150px' }} />
                        </div>
                        <div className="col-sm-3 col-md-1 ml-3">
                            <img src={data.marca_puig.childImageSharp.fluid.src} className="img-fluid" alt="ruffles" />
                        </div>
                        <div className="col-sm-3 col-md-1 mr-4" >
                            <img src={data.marca_stmoritz.childImageSharp.fluid.src} className="img-fluid" alt="Stmoritz" style={{ maxWidth: '130px' }} />
                        </div>
                        <div className="col-sm-3 col-md-1 mr-4 mt-2">
                            <img src={data.marca_mondelez.childImageSharp.fluid.src} className="img-fluid" alt="Raquety" style={{ maxWidth: '150px' }} />
                        </div>
                        <div className="col-sm-3 col-md-1 ml-3">
                            <img src={data.marca_alimentos_polar.childImageSharp.fluid.src} className="img-fluid" alt="natuchips" style={{ maxWidth: '110px' }} />
                        </div>
                        <div className="col-sm-3 col-md-1 mb-2 mr-3">
                            <img src={data.marca_pepsico.childImageSharp.fluid.src} className="img-fluid" alt="pepito" style={{ maxWidth: '150px' }} />
                        </div>
                        <div className="col-sm-3 col-md-1">
                            <img src={data.marca_cosmeticos_lara.childImageSharp.fluid.src} className="img-fluid" alt="Cosmeticos_lara" />
                        </div>
                        <div className="col-sm-3 col-md-1">
                            <img src={data.gomby.childImageSharp.fluid.src} className="img-fluid" alt="gomby" />
                        </div>

                        <div className="col-sm-3 col-md-1">
                            <img src={data.marca_goa.childImageSharp.fluid.src} className="img-fluid" alt="natuchips" />
                        </div>

                    </div>
                </div>
            </section>

            {/*Captacion de clientes*/}
            <p className="text-center">
                <h4 className="text-primary">NUESTROS PROCESOS</h4>
                <a href={'javascript:void(0);'} onClick={() => scrollTo('#proceso_v')} className=""> <MdKeyboardArrowDown color="#ff1616" size="4em" /> </a>
            </p>
            <section className="ftco-section ftco-services-2" id="proceso_v" style={{ padding: '3em 0' }}>
                <div className="container-fluid">
                    <div className="row justify-content-center mb-3 pb-5">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                            <h2 className="mb-4">1 - Captación de Clientes</h2>
                            <p>En Distribuidora Yonlui, c.a. creemos fielmente que un cliente es parte de nuestra empresa</p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-sm-12 col-md-6  col-lg-3 card-process step my-auto" data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease">
                            <div className="icon d-flex align-items-center justify-content-center mb-3 primary-red" style={{ marginBottom: '3px', width: '60px', height: '60px' }}>
                                <h2 style={{ lineHeight: '60px', padding: '6px 0px 0px 0px' }} className="text-center text-white" >1</h2>
                            </div>
                            <span className="text-center text-white d-none d-sm-none d-md-block" ><RiArrowRightLine color="#ff1616" size="3em" className="style_arrow_proceso" /></span>
                            <div className="card mb-2 box_sh_2" style={{ width: "100%", minHeight: "280px", maxHeight: "410px", background: '#FFF' }}>
                                <div className="card-body align-self-stretch box p-3 text-center">
                                    <img src={data.icon_man.childImageSharp.fluid.src} width="27%" alt=" - icon de alcance" />
                                    <h5 className="text-primary-red">Paso #1</h5>
                                    <p className="mb-2 mt-3 text-content">
                                        Cliente es visitado por un Representante de Ventas o nos contacta por medios digitales.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6  col-lg-3 card-process step my-auto" data-sal="slide-right" data-sal-duration="1300" data-sal-easing="ease">
                            <div className="icon d-flex align-items-center justify-content-center mb-3 primary-red" style={{ marginBottom: '3px', width: '60px', height: '60px' }}>
                                <h2 style={{ lineHeight: '60px', padding: '6px 0px 0px 0px' }} className="text-center text-white" >2</h2>
                            </div>
                            <span className="text-center text-white d-none d-sm-none d-md-block" ><RiArrowRightLine color="#ff1616" size="3em" className="style_arrow_proceso" /></span>

                            <div className="card mb-2 box_sh_2" style={{ width: "100%", minHeight: "280px", maxHeight: "410px", background: '#FFF' }}>
                                <div className="card-body align-self-stretch box p-3 text-center">
                                    <img src={data.icon_paper.childImageSharp.fluid.src} width="30%" alt=" - icon de alcance" />
                                    <h5 className="text-primary-red">Paso #2</h5>
                                    <p className="mb-2 mt-3 text-content">
                                        Representante de ventas lo visita para solicitar recaudos y validar la ubicación del establecimiento.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6  col-lg-3 card-process step my-auto" data-sal="slide-right" data-sal-duration="1600" data-sal-easing="ease">
                            <div className="icon d-flex align-items-center justify-content-center mb-3 primary-red" style={{ marginBottom: '3px', width: '60px', height: '60px' }}>
                                <h2 style={{ lineHeight: '60px', padding: '6px 0px 0px 0px' }} className="text-center text-white" >3 </h2>

                            </div>
                            <span className="text-center text-white d-none d-sm-none d-md-block" ><RiArrowRightLine color="#ff1616" size="3em" className="style_arrow_proceso" /></span>
                            <div className="card mb-2 box_sh_2" style={{ width: "100%", minHeight: "280px", maxHeight: "410px", background: '#FFF' }}>
                                <div className="card-body align-self-stretch box p-3 text-center">
                                    <img src={data.icon_checklist.childImageSharp.fluid.src} width="27%" alt=" - icon de alcance" />
                                    <h5 className="text-primary-red">Paso #3</h5>
                                    <p className="mb-2 mt-3 text-content">
                                        Cliente se incorpora en nuestra base de datos
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6  col-lg-3 card-process step my-auto" data-sal="slide-right" data-sal-duration="1900" data-sal-easing="ease">
                            <div className="icon d-flex align-items-center justify-content-center mb-3 primary-red" style={{ marginBottom: '3px', width: '60px', height: '60px' }}>
                                <h2 style={{ lineHeight: '60px', padding: '6px 0px 0px 0px' }} className="text-center text-white" >4</h2>
                            </div>
                            <span className="text-center text-white d-none d-sm-none d-md-block" ><RiArrowRightLine color="rgba(255, 255, 255, 0);" size="3em" className="style_arrow_proceso" /></span>
                            <div className="card mb-2 box_sh_2" style={{ width: "100%", minHeight: "280px", maxHeight: "410px", background: '#FFF' }}>
                                <div className="card-body align-self-stretch box p-3 text-center">
                                    <img src={data.icon_contact_center.childImageSharp.fluid.src} width="27%" alt=" - icon de alcance" />
                                    <h5 className="text-primary-red">Paso #4</h5>
                                    <p className="mb-2 mt-3 text-content">
                                        Se contacta al Cliente para indicarle: Día de la visita de la zona donde está ubicada y día de despacho.
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>

            {/*Procesod e venta*/}
            <section className="ftco-section ftco-services-2" id="proceso_v">
                <div className="container-fluid">
                    <div className="row justify-content-center mb-3 pb-5">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                            <h2 className="mb-4">2 - Ventas y Distribución</h2>
                            <p>Ofrecemos a nuestros aliados
                            comerciales un sistema de Distribución eficiente que garantice el
                        crecimiento</p>
                        </div>
                    </div>
                    <div className="row">


                        <div className="col-sm-12 col-md-6  col-lg-3 card-process step my-auto" data-sal="slide-right" data-sal-duration="1000" data-sal-easing="ease">
                            <div className="icon d-flex align-items-center justify-content-center mb-3 primary-red" style={{ marginBottom: '3px', width: '60px', height: '60px' }}>
                                <h2 style={{ lineHeight: '60px', padding: '6px 0px 0px 0px' }} className="text-center text-white" >1</h2>
                            </div>
                            <span className="text-center text-white d-none d-sm-none d-md-block" ><RiArrowRightLine color="#ff1616" size="3em" className="style_arrow_proceso" /></span>
                            <div className="card mb-2 box_sh_2" style={{ width: "100%", minHeight: "280px", maxHeight: "410px", background: '#FFF' }}>
                                <div className="card-body align-self-stretch box p-3 text-center">
                                    <img src={data.paso_1.childImageSharp.fluid.src} width="27%" alt=" - icon de alcance" />
                                    <h5 className="text-primary-red">Paso #1</h5>
                                    <p className="mb-2 mt-3 text-content">
                                        Representante de Ventas realiza el pedido el día que corresponde  la zona, indica monto del pedido y fecha de despacho.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6  col-lg-3 card-process step my-auto" data-sal="slide-right" data-sal-duration="1300" data-sal-easing="ease">
                            <div className="icon d-flex align-items-center justify-content-center mb-3 primary-red" style={{ marginBottom: '3px', width: '60px', height: '60px' }}>
                                <h2 style={{ lineHeight: '60px', padding: '6px 0px 0px 0px' }} className="text-center text-white" >2</h2>
                            </div>
                            <span className="text-center text-white d-none d-sm-none d-md-block" ><RiArrowRightLine color="#ff1616" size="3em" className="style_arrow_proceso" /></span>

                            <div className="card mb-2 box_sh_2" style={{ width: "100%", minHeight: "280px", maxHeight: "410px", background: '#FFF' }}>
                                <div className="card-body align-self-stretch box p-3 text-center">
                                    <img src={data.paso_2.childImageSharp.fluid.src} width="30%" alt=" - icon de alcance" />
                                    <h5 className="text-primary-red">Paso #2</h5>
                                    <p className="mb-2 mt-3 text-content">
                                        Cliente recibe la visita del Despachador con su pedido en la fecha indicada.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6  col-lg-3 card-process step my-auto" data-sal="slide-right" data-sal-duration="1600" data-sal-easing="ease">
                            <div className="icon d-flex align-items-center justify-content-center mb-3 primary-red" style={{ marginBottom: '3px', width: '60px', height: '60px' }}>
                                <h2 style={{ lineHeight: '60px', padding: '6px 0px 0px 0px' }} className="text-center text-white" >3 </h2>

                            </div>
                            <span className="text-center text-white d-none d-sm-none d-md-block" ><RiArrowRightLine color="#ff1616" size="3em" className="style_arrow_proceso" /></span>
                            <div className="card mb-2 box_sh_2" style={{ width: "100%", minHeight: "280px", maxHeight: "410px", background: '#FFF' }}>
                                <div className="card-body align-self-stretch box p-3 text-center">
                                    <img src={data.paso_3.childImageSharp.fluid.src} width="27%" alt=" - icon de alcance" />
                                    <h5 className="text-primary-red">Paso #3</h5>
                                    <p className="mb-2 mt-3 text-content">
                                        Cliente muestra pago y se verifica.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-6  col-lg-3 card-process step my-auto" data-sal="slide-right" data-sal-duration="1900" data-sal-easing="ease">
                            <div className="icon d-flex align-items-center justify-content-center mb-3 primary-red" style={{ marginBottom: '3px', width: '60px', height: '60px' }}>
                                <h2 style={{ lineHeight: '60px', padding: '6px 0px 0px 0px' }} className="text-center text-white" >4</h2>
                            </div>
                            <span className="text-center text-white d-none d-sm-none d-md-block" ><RiArrowRightLine color="rgba(255, 255, 255, 0);" size="3em" className="style_arrow_proceso" /></span>
                            <div className="card mb-2 box_sh_2" style={{ width: "100%", minHeight: "280px", maxHeight: "410px", background: '#FFF' }}>
                                <div className="card-body align-self-stretch box p-3 text-center">
                                    <img src={data.paso_4.childImageSharp.fluid.src} width="27%" alt=" - icon de alcance" />
                                    <h5 className="text-primary-red">Paso #4</h5>
                                    <p className="mb-2 mt-3 text-content">
                                        Finalmente el Despachador entrega el producto para que el cliente verifique cantidad y calidad de su pedido, dando así su conformidad.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section id="proceso_ventas" className="hero">
                <div className="row justify-content-center">
                    <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                        <h2 className="">Proceso de ventas y Distribución</h2>
                        <p>Ofrecemos a nuestros aliados
                        comerciales un sistema de Distribución eficiente que garantice el
                        crecimiento.</p>
                    </div>
                </div>
                <div className="home-slider js-fullheight owl-carousel">
                    <OwlCarousel
                        className="owl-theme"
                        loop={true}
                        margin={0}
                        nav={true}
                        items={1}
                        autoplay={false}
                        mouseDrag={false}
                        autoplayHoverPause={true}
                    >

                        <div className="slider-item js-fullheight">
                            <div className="overlay"></div>
                            <div className="container-fluid p-0">
                                <div className="row d-md-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" >
                                    <div className="one-third order-md-last img js-fullheight owlCustom" style={{ backgroundImage: `url(${data.uno.childImageSharp.fluid.src})`, height: '100%' }}>
                                        <div className="overlay"></div>
                                    </div>
                                    <div className="one-forth d-flex js-fullheight align-items-center" data-scrollax=" properties: { translateY: '70%' }">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item js-fullheight">
                            <div className="overlay"></div>
                            <div className="container-fluid p-0">
                                <div className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" >
                                    <div className="one-third order-md-last img js-fullheight owlCustom" style={{ backgroundImage: `url(${data.dos.childImageSharp.fluid.src})`, height: '100%' }}>
                                        <div className="overlay"></div>
                                    </div>
                                    <div className="one-forth d-flex js-fullheight align-items-center" data-scrollax=" properties: { translateY: '70%' }">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-item js-fullheight">
                            <div className="overlay"></div>
                            <div className="container-fluid p-0">
                                <div className="row d-flex no-gutters slider-text js-fullheight align-items-center justify-content-end" >
                                    <div className="one-third order-md-last img js-fullheight owlCustom" style={{ backgroundImage: `url(${data.tres.childImageSharp.fluid.src})`, height: '100%' }}>
                                        <div className="overlay"></div>
                                    </div>
                                    <div className="one-forth d-flex js-fullheight align-items-center" data-scrollax=" properties: { translateY: '70%' }">

                                    </div>
                                </div>
                            </div>
                        </div>

                    </OwlCarousel>
                </div>

            </section> */}

            {/*Alcance*/}
            <section className="ftco-section ftco-services-2" style={{ background: '#f5f5f5' }} id="registrate">
                <div className="container">
                    <div className="row justify-content-center mb-2 pb-1" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                        <div className="col-md-7 heading-section text-center ">
                            <span className="subheading">
                                UBICA TU LOCALIDAD
                            </span>
                            <h2 className="mb-2">Alcance</h2>
                            <p>Actualmente ofrecemos nuestros servicios de Distribución en las siguientes regiones</p>
                            <span className="subheading">
                                <img src={data.icon_alcance.childImageSharp.fluid.src} width="15%" alt=" - icon de alcance" />
                            </span>
                        </div>
                    </div>
                    <div className="row justify-content-center">

                        <div className="col-sm-12 col-md-5 mb-3">
                            <div className="custom-column" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                                <div className="custom-column-header pb-0 pt-0">Sede Principal Acarigua</div>
                                <div className="custom-column-content">
                                    <ul className="list-group">
                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdMyLocation color="#093b99" size="1.2em" /> Acarigua</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdMyLocation color="#093b99" size="1.2em" /> Araure
                                        </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdMyLocation color="#093b99" size="1.2em" /> Agua Blanca</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdMyLocation color="#093b99" size="1.2em" /> Cojedito</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdMyLocation color="#093b99" size="1.2em" /> El Playón
                                        </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdMyLocation color="#093b99" size="1.2em" /> Píritu
                                        </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdMyLocation color="#093b99" size="1.2em" /> San Rafael de Onoto</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdMyLocation color="#093b99" size="1.2em" /> Sarare – La Miel</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdMyLocation color="#093b99" size="1.2em" /> Turen
                                        </li> </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-5 mb-3">
                            <div className="custom-column" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <div className="custom-column-header pb-0 pt-0">Sucursal Guanare</div>
                                <div className="custom-column-content">
                                    <ul className="list-group">
                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdMyLocation color="#093b99" size="1.2em" /> Biscucuy
                                        </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdMyLocation color="#093b99" size="1.2em" />
                                        Boconoito
                                        </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-2 pt-1"> <MdMyLocation color="#093b99" size="1.2em" />
                                        Chabasquen
                                        </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdMyLocation color="#093b99" size="1.2em" /> Guanare</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdMyLocation color="#093b99" size="1.2em" /> Guanarito</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdMyLocation color="#093b99" size="1.2em" /> Ospino</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdMyLocation color="#093b99" size="1.2em" /> Papelon</li> </Link>

                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row justify-content-center mb-2 mt-5 pb-1" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                        <div className="col-md-7 heading-section text-center ">
                            <h2 className="mb-2">Tipos de comercios</h2>
                            <span className="subheading">
                                <img src={data.icon_store.childImageSharp.fluid.src} width="15%" alt=" - icon de alcance" />
                            </span>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <div className="custom-column" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                <div className="custom-column-header pb-0 pt-0">Canal Tradicional</div>
                                <div className="custom-column-content">
                                    <ul className="list-group">
                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Abasto</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Bodegas</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Cantinas escolares</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Cyber café</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Feria de verduras </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0">       <MdCheck color="#093b99" size="1.2em" /> Fuente de soda </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Hotel </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Kiosco  </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Kiosco centro comercial </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> No tradicionales </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Licorerías </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Parada inteligente </li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-0 pt-0"> <MdCheck color="#093b99" size="1.2em" /> Restaurantes </li> </Link>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-3">
                            <div className="custom-column" data-sal="fade-in" data-sal-duration="1000" data-sal-easing="ease">
                                <div className="custom-column-header pb-0 pt-0">Canal Mayorista</div>
                                <div className="custom-column-content">
                                    <ul className="list-group">
                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdCheck color="#093b99" size="1.2em" /> Confitería autoservicio</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdCheck color="#093b99" size="1.2em" /> Confitería mostrador</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdCheck color="#093b99" size="1.2em" /> Mayor de víveres autoservicio</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdCheck color="#093b99" size="1.2em" /> Mayor de víveres mostrador</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-1 pt-1"> <MdCheck color="#093b99" size="1.2em" /> Mixto mayor y supermercado</li> </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-3">
                            <div className="custom-column" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                                <div className="custom-column-header pb-0 pt-0">Canal Moderno</div>
                                <div className="custom-column-content">
                                    <ul className="list-group">
                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-2 pt-2"> <MdCheck color="#093b99" size="1.2em" /> Bodegón mini market</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-2 pt-2"> <MdCheck color="#093b99" size="1.2em" /> Cadena nacional</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-2 pt-2"> <MdCheck color="#093b99" size="1.2em" /> Cines</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-3 pt-3"> <MdCheck color="#093b99" size="1.2em" /> Estación de servicio mostrador</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-2 pt-2"> <MdCheck color="#093b99" size="1.2em" /> Farmacia regional</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-2 pt-2"> <MdCheck color="#093b99" size="1.2em" /> Panadería</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-2 pt-3"> <MdCheck color="#093b99" size="1.2em" /> Supermercado independiente</li> </Link>

                                        <Link to="/registrate" className="color_2"><li className="list-group-item pb-2 pt-2"> <MdCheck color="#093b99" size="1.2em" /> Tienda de conveniencia</li> </Link>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*Registrate*/}
            <section className="ftco-section contact-section ftco-no-pb" id="acc_register" >
                <div className="container">
                    <div className="row justify-content-center mb-1 pb-1">
                        <div className="col-md-7 heading-section text-center ">
                            <h4 className="mb-4" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">¿Quieres ser parte de nuestra Red de Distribución?</h4>
                            <span className="subheading" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                <img src={data.icon_register.childImageSharp.fluid.src} width="15%" alt=" - icon de alcance" />
                            </span>
                        </div>
                    </div>

                    <div className="row justify-content-center">

                        <div className="col-md-12 col-sm-12 mb-1" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">

                            <div className="col-md-12 mb-1 text-center">
                                <Link to='/registrate' className="btn btn-primary px-5 py-3">Regístrate</Link>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            {/*Servicios*/}
            <section className="ftco-section ftco-services-2" style={{ background: '#f5f5f5' }} id="servicios">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-5" >
                        <div className="col-md-5 heading-section text-center ">
                            <span className="subheading" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                                <img src={data.stock.childImageSharp.fluid.src} width="60%" alt=" - icon de alcance" />
                            </span>
                        </div>

                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                            <span className="subheading">servicios</span>
                            <h2 className="mb-4">Almacenamiento y Logística</h2>
                            <p className="text-justify">En Distribuidora Yonlui, c.a. le ofrecemos la posibilidad de almacenar sus productos sin necesidad de alquilar su propio almacén. Habilitamos espacios en nuestros almacenes para tu emprendimiento. Solo debe verificar que la mercancía se pueda apilar de manera segura en paletas de 1.20 x 1.20 mts y hasta 1.80 mts de altura. Esto le permite saber cuántos espacios necesita contratar y evaluar costos.</p>
                        </div>
                        <div className="col-sm-12 col-md-12 text-center">
                            <a href={'javascript:void(0);'} onClick={() => scrollTo('#contact-section')} className="btn btn_white px-5 py-3 mt-3">Contáctanos</a>
                        </div>
                    </div>

                </div>
            </section>

            {/* <section className="ftco-section ftco-services-2" style={{ background: '#f5f5f5' }} id="tips">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-5" data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                        <div className="col-md-7 heading-section text-center ">
                            <span className="subheading">nuestros tips</span>
                            <h2 className="mb-4">Tips</h2>
                            <p>Ofrecemos a nuestros aliados los mejores tips que facilitarán mucho sus vidas.</p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-sm-12 col-md-12">
                            <div className="coach-wrap  d-sm-flex" data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                                <div className="img coach-img ml-2" style={{ backgroundImage: `url(${data.choco.childImageSharp.fluid.src})` }}></div>
                                <div className="text pl-md-5">
                                    <h2><a href={'javascript:void(0);'}>¿Cómo almacenar bien chocolate?</a></h2>
                                    <p> Para guardar el chocolate en la nevera, tienes que protegerlo bien. Debes envolverlo en un papel que absorba la humedad y luego en una bolsa de plástico hermética..</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                            <div className="coach-wrap  d-sm-flex" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease">
                                <div className="img coach-img" style={{ backgroundImage: `url(${data.lays.childImageSharp.fluid.src})` }}></div>
                                <div className="text pl-md-5">
                                    <h2><a href={'javascript:void(0);'}>¿Cómo almacenar bien las frituras?</a></h2>
                                    <p>Deja que las frituras se enfríen a temperatura ambiente antes de almacenarlas en un recipiente hermético. Añadir arroz sin cocinar al fondo del recipiente ayudará a evitar que se ablanden.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 text-center">
                            <Link to="/tips" className="btn btn-primary px-5 py-3 mt-3">Ver más</Link>
                        </div>

                    </div>
                </div>
            </section> */}

            {/* <section className="ftco-counter img ftco-section ftco-no-pt ftco-no-pb" id="about">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-6 col-lg-5 d-flex" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                            <div className="img d-flex align-self-stretch align-items-center" style={{ backgroundImage: `url(${data.slide_6.childImageSharp.fluid.src})` }}>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-7 pl-lg-5 py-5">
                            <div className="py-md-5">
                                <div className="row justify-content-start pb-3">
                                    <div className="col-md-12 heading-section " data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                                        <span className="subheading">Distribuidora Yonlui C.A.</span>
                                        <h2 className="mb-4" style={{ fontSize: '34px', textTransform: 'capitalize' }}><span className="number" data-number="11">11</span> años Prestando servicio de calidad</h2>
                                        <p>
                                            Tenemos el firme propósito de ofrecer a nuestros aliados
                                            comerciales un sistema de Distribución eficiente que garantice el
                                            crecimiento esperado para sus marcas mediante la ejecución y
                                            monitoreo permanente de indicadores de gestión, contando con
                                            un equipo de trabajo altamente preparado, motivado y
                                            comprometido y que a su vez, asegure la rentabilidad esperada
                                            para sus accionistas
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/*Unetenos*/}
            <section className="ftco-section contact-section ftco-no-pb" id="se_parte">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                            <span className="subheading">
                                Únetenos
                            </span>
                            <h2 className="mb-4">Sé parte de Nosotros</h2>
                            <p>Si buscas un entorno laboral en el que puedas desarrollarte y crecer como ser humano integral, con nuestros valores y calidad en el servicio, la familia Distribuidora Yonlui, c.a. te espera con sus puertas abiertas.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-3 col-sm-12 mb-1 text-center" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                            <span className="subheading">
                                <img src={data.icon_unetenos.childImageSharp.fluid.src} width="60%" alt=" - icon de alcance" />
                            </span>
                        </div>
                        <div className="col-md-9 col-sm-12 mb-1" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                            <form
                                onSubmit={send_form_joinus}
                                action="https://api-email.metalinks.com.ve/api/v1/send/eyJpdiI6IkcyaFpMZUV4Mm12bzZtZFZcL1RhNHRnPT0iLCJ2YWx1ZSI6IjlJckN4R1wvYkxMZ3pmelUwTXZadk53PT0iLCJtYWMiOiJlMjgzM2U0NTQ5YWIzOWMyNjhkMmFiMDlmMDFkYjE3YTBhMTliYWU0MmJiNjE3MDZiNWY0Mzk2NWZiZmZiODQxIn0="

                                method="POST"
                                encType="multipart/form-data"
                                id="form_joinus"
                                className="bg-light row p-4 p-md-5 contact-form">

                                <div className="col-md-5 mb-1">
                                    <input required type="text" name="email" className="form-control" placeholder="Correo electrónico" />
                                </div>
                                <div className="col-md-5 mb-1">
                                    <input required type="file" name="document" className="form-control" />
                                </div>
                                <div className="col-md-2 mb-1">
                                    <input disabled={c_stateBtn}  type="submit" value={c_titleBtn} className="btn btn-primary py-3 px-5" />
                                </div>
                                <input required type="hidden" name="type_form" value="3" />


                            </form>
                        </div>

                    </div>

                </div>
            </section>


            {/*Contacto*/}
            <section className="ftco-section contact-section ftco-no-pb" id="contact-section">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1500" data-sal-easing="ease">
                            <span className="subheading">Contacto</span>
                            <h2 className="mb-4">Contáctanos</h2>
                            <p>Para estar siempre bien informado sobre nuestros productos y servicios</p>
                        </div>
                    </div>

                    <div className="row block-9">
                        <div className="col-md-7 order-md-last d-flex" data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                            <form
                                onSubmit={send_form}
                                action="https://api-email.metalinks.com.ve/api/v1/send/eyJpdiI6IkcyaFpMZUV4Mm12bzZtZFZcL1RhNHRnPT0iLCJ2YWx1ZSI6IjlJckN4R1wvYkxMZ3pmelUwTXZadk53PT0iLCJtYWMiOiJlMjgzM2U0NTQ5YWIzOWMyNjhkMmFiMDlmMDFkYjE3YTBhMTliYWU0MmJiNjE3MDZiNWY0Mzk2NWZiZmZiODQxIn0="
                                method="POST"
                                encType="multipart/form-data"
                                id="form_contact"
                                className="bg-light p-4 p-md-5 contact-form">
                                <div className="form-group">
                                    <input required type="text" name="name" className="form-control" placeholder="Tú Nombre" />
                                </div>
                                <div className="form-group">
                                    <input required type="email" name="email" className="form-control" placeholder="Correo electrónico" />
                                </div>
                                <div className="form-group">
                                    <input required type="text" name="subject" className="form-control" placeholder="Asunto" />
                                </div>
                                <div className="form-group">
                                    <textarea required name="message" id="" cols="30" rows="7" className="form-control" placeholder="Mensaje"></textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="document">Documento identificador (RIF/CI)</label>
                                    <input required type="file" name="document" className="form-control file" placeholder="Documento identificador" />
                                </div>
                                <div className="form-group">
                                    <input disabled={c_stateBtn}  type="submit" value={c_titleBtn} className="btn btn-primary py-3 px-5" />

                                </div>
                                <input required type="hidden" name="type_form" value="1" />

                            </form>

                        </div>

                        <div className="col-md-5 d-flex">
                            <div className="row d-flex contact-info mb-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                                <div className="col-md-12 ">
                                    <div className="box p-2 px-3 bg-light d-flex">
                                        <div className="icon mr-3">
                                            <span className="icon-map-signs"></span>
                                        </div>
                                        <div>
                                            <h3 className="mb-3">Dirección</h3>
                                            <p> <b>Sede Principal Acarigua</b> </p>
                                            <p> <b>Oficinas Comerciales:</b>  Calle 33 esquina Avenida 27, Primer Piso Sector Andrés Bello Acarigua Estado Portuguesa.</p>
                                            <p> <b>Depósito:</b>  Calle 5, Parcela 54. Galpón G-04 Zona Industrial Acarigua. Acarigua, Estado Portuguesa.</p>
                                            <p> <b>Teléfonos de contactos:</b> </p>
                                            <p><a href="tel://02554140783"><MdPhone color="#ef4430" size="1.5em" /> 0255-4140783</a> ---  <a href="tel://4128502054"><RiSmartphoneLine color="#ef4430" size="1.5em" /> +58 412-8502054</a></p>
                                            <p> <b>Sucursal Guanare:</b> Avenida Simón Bolívar con calle 01 Al lado del C.C. Rusinca Portuguesa.</p>
                                            <p> <b>Teléfonos de contactos:</b> </p>
                                            <p><a href="tel://02574154892"><MdLocalPhone color="#ef4430" size="1.5em" /> 0257-4154892</a> --- <a href="tel://4145484417"><RiSmartphoneLine color="#ef4430" size="1.5em" /> +58 414-5484417</a></p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 ">
                                    <div className="box p-2 px-3 bg-light d-flex">
                                        <div className="icon mr-3">
                                            <span className="icon-paper-plane"></span>
                                        </div>
                                        <div>
                                            <h3 className="mb-3">Correo electrónico</h3>
                                            <p><a href="mailto:contactoventas@yonlui.com">contactoventas@yonlui.com</a></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section ftco-no-pt ftco-no-pb">
                <div className="row">

                    <div className="col-sm-12 col-md-6 ">
                        <h3 className="text-micolor  ml-2">Sede Principal Acarigua</h3>
                        <div id="map" className="bg-white">
                            <iframe id="maps" title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.393888995246!2d-69.20441598520928!3d9.561273193152783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e7dc13923dfd061%3A0xf2244c4daa090bc3!2sSede%20Principal%20Acarigua%2C%20Distribuidora%20Yonlui%2C%20c.a.!5e0!3m2!1ses-419!2sve!4v1589109344926!5m2!1ses-419!2sve" frameborder="0" style={{ border: 0, width: '100%', height: '100%' }} allowfullscreen></iframe>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-6 ">
                        <h3 className="text-micolor  ml-2">Sucursal Guanare</h3>
                        <div id="map" className="bg-white">
                            <iframe id="maps" title="Mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d985.0785638089969!2d-69.738918156414!3d9.03507106193611!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e7cf5490b26540d%3A0xcca4605dc84d5af0!2sDistribuidora%20Yonlui%20C.A%20(Sucursal%20Guanare)!5e0!3m2!1ses!2sve!4v1585603953980!5m2!1ses!2sve" frameborder="0" style={{ border: 0, width: '100%', height: '100%' }} allowfullscreen></iframe>
                        </div>
                    </div>

                </div>

            </section>

        </Layout>
    )
}


export default IndexPage
